import React, { useEffect } from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import { Helmet } from "react-helmet";

// Previews
import { withPreview } from "gatsby-source-prismic";

// Context
import { PageTypeConsumer } from "../components/context/page-type";

// Components
import { AspectRatioImageContainer } from "../components/containers/aspect-ratio-image-container";

// Utils
import { ConditionalWrapper } from "../utils/conditional-wrapper";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  width: 100%;

  padding: 190px 100px 0 100px;

  max-width: 1400px;
  margin: 0 auto;

  @media (max-width: 1200px) {
    padding: 190px 50px 0 50px;
  }

  @media (max-width: 768px) {
    padding: 110px 0 0 0;
  }
`;

const Intro = styled.div`
  width: 100%;

  padding: 0 0 100px 0;
  margin: 0 auto;

  & .text {
    width: 100%;
    max-width: 800px;
    text-align: center;

    padding: 50px 0 0 0;
    margin: 0 auto;

    font-size: 32px;
    line-height: 115%;
    letter-spacing: 0.01em;

    & p:first-of-type {
      margin-top: 0;
    }

    & p:last-of-type {
      margin-bottom: 0;
    }

    @media (max-width: 1300px) {
      font-size: 28px;
      line-height: 36px;
    }

    @media (max-width: 768px) {
      padding: 40px 20px 0 20px;

      font-size: 24px;
      line-height: 27px;
    }
  }

  @media (max-width: 768px) {
    padding: 0 0 45px 0;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 60px;

  width: 100%;

  @media (max-width: 1200px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 768px) {
    display: block;

    grid-gap: 0;
    grid-template-columns: unset;
    grid-auto-flow: unset;
  }
`;

const Partner = styled.div`
  grid-column: span 1;
  margin: 0 0 60px 0;

  & .image {
    position: relative;
    overflow: hidden;

    cursor: pointer;

    & a {
      display: block;

      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }

    & .partner-text {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;

      display: flex;
      align-content: center;
      flex-direction: row;
      flex-wrap: wrap;

      color: #fff;
      background-color: #df3f1c;

      padding: 20px;

      transition: 750ms opacity ease;
      opacity: 0;

      & p {
        font-size: 14px;
        line-height: 18px;

        letter-spacing: 0.04em;
        text-align: center;

        width: 100%;

        @media (max-width: 1300px) {
          font-size: 12px;
          line-height: 16px;
        }

        @media (max-width: 1200px) {
          font-size: 16px;
          line-height: 22px;
        }
      }
    }

    & img,
    & video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &:hover {
      & .partner-text {
        opacity: 1;
      }
    }
  }

  & .text-container {
    text-align: center;

    & .title {
      margin: 15px 0 0 0;

      & h3 {
        font-size: 36px;
        line-height: 36px;
        letter-spacing: 0.01em;

        @media (max-width: 1300px) {
          font-size: 28px;
          line-height: 36px;
        }

        @media (max-width: 900px) {
          font-size: 26px;
          line-height: 30px;
        }
      }
    }

    & .caption {
      margin: 5px 0 0 0;

      & p {
        font-size: 18px;
        line-height: 128%;
        letter-spacing: 0.04em;

        margin: 0;

        @media (max-width: 1300px) {
          font-size: 14px;
          line-height: 18px;
        }
      }
    }
  }
`;

const TheVillageContent = ({ data, setPageType }) => {
  useEffect(() => {
    setPageType("community");
  }, []);

  const content = data.prismicCommunity.data.body.map((content, index) => (
    <Partner key={content.id}>
      <div className="image">
        <AspectRatioImageContainer image={null} padding={100}>
          <ConditionalWrapper
            condition={content.primary.external_link.url}
            wrapper={children => (
              <a href={content.primary.external_link.url} target="_blank">
                {children}
              </a>
            )}
          >
            {content.slice_type === "partner_-_image" &&
              content.primary.image.fluid !== null && (
                <img
                  srcSet={content.primary.image.fluid.srcSetWebp}
                  src={content.primary.image.fluid.srcWebp}
                  alt={content.primary.image.alt}
                  loading="lazy"
                />
              )}

            {content.slice_type === "partner_-_video" &&
              content.primary.video.url !== null && (
                <video
                  src={content.primary.video.url}
                  muted
                  autoPlay
                  loop
                  playsInline
                  preload="metadata"
                />
              )}

            <div
              className="partner-text caslon-doric"
              dangerouslySetInnerHTML={{
                __html: content.primary.partner_text.html,
              }}
            />
          </ConditionalWrapper>
        </AspectRatioImageContainer>
      </div>

      <div className="text-container">
        <div
          className="title"
          dangerouslySetInnerHTML={{
            __html: content.primary.partner_name.html,
          }}
        />
        <div
          className="caption caslon-doric"
          dangerouslySetInnerHTML={{
            __html: content.primary.partner_caption.html,
          }}
        />
      </div>
    </Partner>
  ));

  return (
    <>
      <Helmet
        title={`The Village – Park Village`}
        meta={[
          {
            name: "og:title",
            content: `The Village – Park Village`,
          },
          {
            name: "twitter:title",
            content: `The Village – Park Village`,
          },
        ]}
      />
      <Container>
        <Intro>
          <div
            className="text"
            dangerouslySetInnerHTML={{
              __html: data.prismicCommunity.data.text.html,
            }}
          />
        </Intro>
        <Grid>{content}</Grid>
      </Container>
    </>
  );
};

const TheVillage = ({ data }) => (
  <PageTypeConsumer>
    {({ setPageType }) => (
      <TheVillageContent setPageType={setPageType} data={data} />
    )}
  </PageTypeConsumer>
);

export default withPreview(TheVillage);

export const query = graphql`
  {
    prismicCommunity {
      data {
        text {
          html
        }
        body {
          ... on PrismicCommunityBodyPartnerImage {
            id
            slice_type
            primary {
              image {
                fluid {
                  srcSetWebp
                  srcWebp
                }
                alt
              }
              partner_name {
                html
              }
              partner_caption {
                html
              }
              partner_text {
                html
              }
              external_link {
                url
              }
            }
          }
          ... on PrismicCommunityBodyPartnerVideo {
            id
            slice_type
            primary {
              video {
                url
              }
              partner_name {
                html
              }
              partner_caption {
                html
              }
              partner_text {
                html
              }
              external_link {
                url
              }
            }
          }
        }
      }
    }
  }
`;
